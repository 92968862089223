import React from "react";
import "./css/App.css";
import cog from "./img/cog.svg";
import Credits from "./components/Credits";
import GameStart from "./components/GameStart";
import MainLogo from "./img/MainLogo.svg";

class App extends React.Component {
  state = {
    openClose: "none",
    goCredits: false,
    goMainMenu: false,
    startAgain: false

  }

  openCloseMenu = () => {
    if (this.state.openClose === "none") {
      return this.setState({
        openClose: null
      })
    } else {
      return this.setState({
        openClose: "none"
      })
    }
  }

  changeCredits = () => {
    this.setState({
      goCredits: true,
      goMainMenu: false,
      startAgain: false
    })
  }

  changeMainMenu = () => {
    this.setState({
      goMainMenu: true,
      goCredits: false,
      startAgain: false
    })
  }

  changeAgain = () => {
    this.setState({
      startAgain: true,
      goCredits: false,
      goMainMenu: false

    })
  }

  footerMenu = () => {
    return (
      <div className="footer-menu">
        <div className="grid-container-footer-1">
          <div className="grid-footer-1" onClick={this.openCloseMenu}><img className="cog" src={cog} alt="Menu" /></div>
        </div>
        <div style={{ display: this.state.openClose }} className="grid-container-footer-2 hidden-menu">
          <div className="grid-footer" onClick={this.changeMainMenu}>Main Menu</div>
        </div>
      </div>
    );
  }

  mainMenu = () => {
    return (
      <div className="on-change-anim">
        <p><img src={MainLogo} alt="logo the dungeon" className="my-10 main-logo" /></p>
        <div className="my-20">
          <p><button onClick={this.changeAgain} className="btn-main-menu my-10">New Game</button></p>
          <p><button onClick={this.changeCredits} className="btn-main-menu my-10">Credits</button></p>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.goCredits === true) {
      return (
        <div>
          <Credits />
          {this.footerMenu()}
        </div>);
    } else if (this.state.goMainMenu === true) {
      return (
        <div>
          {this.mainMenu()}
        </div>
      );
    } else if (this.state.startAgain === true || this.state.startNewGame === true) {
      return (
        <div>
          <GameStart />
          {this.footerMenu()}
        </div>
      );
    }
    else {
      return (
        < div>
          {this.mainMenu()}
        </div >
      );
    }
  }
}

export default App;

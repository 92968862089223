import React from "react";
import "./GameStart.css";
import GameData from "../data/data";
import GameEnd from "./GameEnd";
import heart from "../img/heart.svg";
import coins from "../img/coins.svg";
import swords from "../img/swords.svg";
import Credits from "./Credits";

class GameStart extends React.Component {
  constructor() {
    super();
    this.state = {
      index: 0,
      text: GameData,
      health: 100,
      attack: 3,
      shinyCrystal: false,
      shinySword: false,
      shinyAmulet: false,
      shinyRingRare: false,
      witchCompanion: false,
      onValueChangeAnim: "",
      onValueChangeAnimSword: "",
      onValueChangeAnimText: "",
      finalScore: 100,
      showScore: false,
      goCredits: false,
      coins: 0
    };
  }
  goToNext = answer => {
    this.setState({
      onValueChangeAnim: "",
      onValueChangeAnimSword: ""
    })
    switch (answer) {
      case "Start":
      case "Go in":
      case "Next":
      case "Choose the right path":
      case "Approach her":
      case "You politely refuse the offer":
      case "Push the right lever":
      case "Continue to the next flor":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length
        });
        break;

      case "Grab that shiny thing, maybe it costs something":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          shinyCrystal: true,
          finalScore: this.state.finalScore + 50
        });
        break;

      case "Ignore the crystal":
      case "Continue":
      case "Use your sword to attack":
      case "You tell her about the treasure you are looking for":
      case "Don't drink and return to the hall":
        this.setState({
          index: (this.state.index + 2) % this.state.text.length
        });
        break;

      case "You are agree":
        this.setState({
          index: (this.state.index + 2) % this.state.text.length,
          witchCompanion: true,
          finalScore: this.state.finalScore + 50
        });
        break;

      case "Use your shoe to attack":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          health: this.state.health - 5,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore - 5

        });

        break;

      case "Choose the left path":
        this.setState({
          index: (this.state.index + 7) % this.state.text.length
        });
        break;

      case "Return":
        this.setState({
          index: (this.state.index - 7) % this.state.text.length
        });
        break;

      case "You tell her that it’s not her business":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          attack: this.state.attack - 2,
          onValueChangeAnimSword: "on-change-anim",
          finalScore: this.state.finalScore - 50
        });
        break;

      case "Put yourself together and continue":
        this.setState({
          index: (this.state.index + 4) % this.state.text.length
        });
        break;

      case "Give her the crystal you found at the entrance":
        if (this.state.shinyCrystal === true) {
          this.setState({
            index: (this.state.index + 2) % this.state.text.length,
            shinyCrystal: false,
            shinySword: true,
            attack: this.state.attack + 2,
            onValueChangeAnimSword: "on-change-anim",
            finalScore: this.state.finalScore + 50
          });
        } else {
          this.setState({
            index: (this.state.index + 1) % this.state.text.length
          });
        }
        break;

      case "Back":
        this.setState({
          index: (this.state.index - 1) % this.state.text.length
        });
        break;

      case "Go for it!":
        this.setState({
          index: (this.state.index - 4) % this.state.text.length
        });
        break;
      case "Attack her":
        this.setState({
          index: (this.state.index + 3) % this.state.text.length,
          health: this.state.health - 20,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore - 20

        });
        break;

      case "Continue your journey":
        this.setState({
          index: (this.state.index - 5) % this.state.text.length

        });
        break;

      case "Give her some of your precious money":
        this.setState({
          index: (this.state.index + 4) % this.state.text.length

        });
        break;

      case "Continue through the dungeon":
        this.setState({
          index: (this.state.index - 6) % this.state.text.length

        });
        break;

      case "Try to be stealthy and continue":
        this.setState({
          index: (this.state.index + 12) % this.state.text.length

        });
        break;
      case "Prepare for the battle":
        this.setState({
          index: (this.state.index + 7) % this.state.text.length

        });
        break;

      case "Ignore the dead body":
      case "Next move":
      case "Don't drink and come back to the hall":
        this.setState({
          index: (this.state.index + 3) % this.state.text.length

        });
        break;

      case "Attack the orc":
        if (this.state.witchCompanion === true) {
          this.setState({
            index: (this.state.index + 3) % this.state.text.length

          });
        } else {
          if (this.state.attack === 3 || this.state.attack === 1) {
            this.setState({
              index: (this.state.index + 2) % this.state.text.length,
              health: this.state.health - 15,
              onValueChangeAnim: "on-change-anim",
              finalScore: this.state.finalScore - 15
            });
          }
          if (this.state.attack === 5) {
            this.setState({
              index: (this.state.index + 1) % this.state.text.length
            });
          }
        }
        break;

      case "Loot the orc":
        this.setState({
          index: (this.state.index + 2) % this.state.text.length,
          shinyAmulet: true,
          attack: this.state.attack + 2,
          onValueChangeAnimSword: "on-change-anim",
          finalScore: this.state.finalScore + 50


        });
        break;

      case "Drink from the red pool":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          health: this.state.health + 20,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore + 20

        });
        break;

      case "Drink from the green pool":
        this.setState({
          index: (this.state.index + 2) % this.state.text.length,
          health: this.state.health - 30,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore - 30

        });
        break;

      case "Investigate the place":
        this.setState({
          index: (this.state.index + 3) % this.state.text.length

        });
        break;

      case "Make a decision":
        this.setState({
          index: (this.state.index - 3) % this.state.text.length

        });
        break;

      case "Drink anyway":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          health: this.state.health - 30,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore - 30

        });
        break;

      case "Drink again":
        this.setState({
          index: (this.state.index + 0) % this.state.text.length,
          health: this.state.health - 30,
          onValueChangeAnim: "on-change-anim"

        });
        break;

      case "Try to speak with him":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          health: this.state.health - 15,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore - 15

        });
        break;

      case "Rush to attack him":
        if (this.state.witchCompanion === true) {
          this.setState({
            index: (this.state.index + 2) % this.state.text.length

          });
        } else {
          if (this.state.attack <= 3) {
            this.setState({
              index: (this.state.index + 3) % this.state.text.length,
              health: this.state.health - 10,
              onValueChangeAnim: "on-change-anim",
              finalScore: this.state.finalScore - 10
            });
          }
          if (this.state.attack === 5) {
            this.setState({
              index: (this.state.index + 4) % this.state.text.length,
              health: this.state.health - 10,
              onValueChangeAnim: "on-change-anim",
              finalScore: this.state.finalScore - 10
            });
          }
          if (this.state.attack > 5) {
            this.setState({
              index: (this.state.index + 5) % this.state.text.length
            });
          }
        }
        break;

      case "Strike again":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          health: this.state.health - 10,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore - 10
        });
        break;

      case "Loot the dead cultist":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          health: this.state.health - 30,
          shinyRingRare: true,
          attack: this.state.attack + 3,
          onValueChangeAnim: "on-change-anim",
          onValueChangeAnimSword: "on-change-anim",
          finalScore: this.state.finalScore + 20
        });
        break;

      case "Step back and see around":
        this.setState({
          index: (this.state.index + 4) % this.state.text.length
        });
        break;

      case "Push the left lever":
        this.setState({
          index: (this.state.index + 15) % this.state.text.length,
          health: this.state.health - 15,
          onValueChangeAnim: "on-change-anim",
          finalScore: this.state.finalScore - 15

        });
        break;

      case "Turn around":
        this.setState({
          index: (this.state.index - 10) % this.state.text.length
        });
        break;

      case "Start again":
        this.setState({
          index: 0,
          text: GameData,
          health: 100,
          attack: 3,
          shinyCrystal: false,
          shinySword: false,
          shinyAmulet: false,
          shinyRingRare: false,
          witchCompanion: false,
          showScore: false,
          finalScore: 100,
          coins: 0
        });
        break;

      case "Try again":
        this.setState({
          index: 0
        });
        break;

      case "Go to credits":
        this.setState({
          goCredits: true
        });
        break;

      case "Go to next floor":
        this.setState({
          index: (this.state.index + 1) % this.state.text.length,
          showScore: true
        });
        break;

      default:
    }
  };

  startAgain = () => {
    this.setState = ({
      health: 100
    })
  }


  render() {
    const item = this.state.text[this.state.index];
    if (this.state.health <= 0) {
      return (
        <GameEnd />
      );
    } else if (this.state.goCredits === true) {
      return <Credits />;
    } else {
      return (
        <div className="main-container height-100 on-change-anim">
          <div className="grid-container">
            <div className="grid-item">
              <p className={this.state.onValueChangeAnim} > <img className="icons-img" src={heart} alt="your health" />{this.state.health}</p>
            </div>
            <div className="grid-item">
              <p className={this.state.onValueChangeAnimSword} ><img className="icons-img" src={swords} alt="your attack" /> {this.state.attack}</p>
            </div>
            <div className="grid-item">
              <p><img className="icons-img" src={coins} alt="your attack" /> {this.state.coins}</p>
            </div>
          </div>
          <div>
            <p className="my-20">{item.text}</p>
            {(this.state.showScore === true) && <p className="my-20">Your final score is <strong>{this.state.finalScore}</strong></p>}
          </div>
          <img className={item.css} alt="" src={item.img}></img>
          <p style={{ color: "green" }} className="m-10">{item.effectPositive}</p>
          <p style={{ color: "red" }} className="m-10">{item.effectNegative}</p>
          {item.answers.map((item, i) => {
            return (
              <p key={i}>
                <button className="btn-choose m-5" onClick={() => this.goToNext(item)}>
                  {item}
                </button>
              </p>
            );
          })}
        </div>
      );
    }
  }
}

export default GameStart;

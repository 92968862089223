import React from 'react';

class Credits extends React.Component {
    render() {
        return (

            <div className="main-container height-100 on-change-anim">
                <h1>Credits</h1>
                <p className="my-20">Hi! My name is Daniel. I am a digital artist, musician and Front End Developer. This is one of my projects. If you want to know more, please feel free to contact me: <a href="mailto: daniel@fractaart.com">daniel@fractaart.com</a></p>
                <p className="my-20">I used some images from <a rel="noopener noreferrer" target="_blank" href="https://pixabay.com/">pixabay.com</a> and character illustrations was generated by the free online tool I don't remember the name :( .</p>
                <p className="my-20">At the current stage the game has only 1 floor. I will try to make more as soon as I can. You can still cheer me up by sending an email :3</p>
            </div>
        )
    }
}

export default Credits;
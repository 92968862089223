import React from 'react';
import App from '../App';

class GameEnd extends React.Component {
    state = {
        iscliked: false
    }

    startAgain = () => {
        this.setState({
            iscliked: true
        })
    }

    render() {
        if (this.state.iscliked === true) {
            return (
                <App />
            );
        }
        return (
            <div className="main-container height-100 on-change-anim">
                <h1>You was defeated!</h1>
                <button className="btn-main-menu my-20" onClick={this.startAgain}>Go to Main</button>
            </div>
        );
    }
}

export default GameEnd;
import shinyCrystal from "../img/cards/shinyCrystal.jpg";
import shinyAmulet from "../img/cards/shinyAmulet.jpg";
import shinySword from "../img/cards/shinySword.jpg";
import shinyRareRing from "../img/cards/shinyRareRing.jpg";
import witchCharacter from "../img/cards/witchCharacter.jpg";
import witchCompanion from "../img/cards/witchCompanion.jpg";
import orcEnemy from "../img/cards/orcEnemy.jpg";
import cultistEnemy from "../img/cards/cultistEnemy.jpg";
import ladyCharacter from "../img/cards/ladyCharacter.jpg";
import ratEnemy from "../img/cards/ratEnemy.jpg";


const GameData = [
  {
    id: 0,
    answers: ["Start"],
    image: "",
    text: "This is a text game. To proceed you will have to choose the answer. Your decisions will directly influence the story, so choose wisely. If your health goes to 0 you will lose the run. Your attack will also affect how well you perform in battle. Good Luck!"
  },
  {
    id: 1,
    answers: ["Go in"],
    image: "",
    text:
      "You are an adventure looking for a way to get rich. You heard a story about the cave with monsters and treasures. You found that cave and decided to go in…"
  },
  {
    id: 2,
    answers: [
      "Grab that shiny thing, maybe it costs something",
      "Ignore the crystal"
    ],
    text:
      "There is no light so you use your torch. You are surprised by a strange shiny crystal near the entrance. What will you do? "
  },
  {
    id: 3,
    answers: ["Next"],
    text: "You got shiny crystal",
    effectPositive: "Shiny Crystal + 1",
    img: shinyCrystal,
    css: "img-item"
  },
  {
    id: 4,
    answers: ["Use your sword to attack", "Use your shoe to attack"],
    text:
      "You go deep in and hear some noise. You were ambushed! By a mighty rat! Prepare to fight!",
    img: ratEnemy,
    css: "img-character"
  },
  {
    id: 5,
    answers: ["Continue"],
    text:
      "The rat was not impressed with your fearsome shoe and fights back. You finally change your mind and use a sword. The rat is dead.",
    effectNegative: "You lose - 5 health"
  },
  {
    id: 6,
    answers: ["Next"],
    text: "You strike with force and rat fall dead."
  },
  {
    id: 7,
    answers: ["Choose the right path", "Choose the left path"],
    text: "You continue your journey and soon you need to make a choice."
  },
  {
    id: 8,
    answers: [
      "You tell her that it’s not her business",
      "You tell her about the treasure you are looking for"
    ],
    text:
      "You go deeper and deeper. After an hour you finally see a light. You are approached by a woman (Definitely a witch). She smiles at you and asks about the purpose of your journey to this place.",
    img: witchCharacter,
    css: "img-character"
  },
  {
    id: 9,
    answers: ["Put yourself together and continue"],
    text:
      "She laughs, but doesn’t say anything. Something is not right. She was very surprised by your rude answer. Soon you find yourself sleepy and fall down. You wake up dizzy and without a sword.",
    effectNegative: "You lose 2 attack"
  },
  {
    id: 10,
    answers: ["You politely refuse the offer", "You are agree"],
    text: "She is not surprised by the answer and asks if she can join you.",
    img: witchCharacter,
    css: "img-character"
  },
  {
    id: 11,
    answers: ["Continue"],
    text: "She looks upset and goes away."
  },
  {
    id: 12,
    answers: ["Next"],
    text: "The witch joins your party",
    effectPositive: "You got Witch companion!",
    img: witchCompanion,
    css: "img-item"
  },
  {
    id: 13,
    answers: ["Prepare for the battle", "Try to be stealthy and continue"],
    text: "You go deeper through the dungeon thinking about shiny treasure and gold. Suddenly you hear strange noises. What will you do?"
  },
  {
    id: 14,
    answers: ["Approach her", "Return"],
    text: "After some steps you see a figure of a woman. What will you do?",
    img: ladyCharacter,
    css: "img-character"
  },
  {
    id: 15,
    answers: ["Attack her", "Give her some of your precious money", "Give her the crystal you found at the entrance"],
    text: "She starts to speak with you: “Hello traveller, do you have some change?”",
    img: ladyCharacter,
    css: "img-character"
  },
  {
    id: 16,
    answers: ["Back"],
    text: "And you don't have it, cause you didn't pick it up."
  },
  {
    id: 17,
    answers: ["Go for it!"],
    text: "She looks happy and gives you a sword ( nice sword, shiny one with ornaments and stuff ). She goes away and you feel happy and stronger. Yeah, I want more loot!",
    effectPositive: "You got a Shiny Sword! Attack + 2",
    img: shinySword,
    css: "img-item"
  },
  {
    id: 17,
    answers: ["Continue your journey"],
    text: "The strange woman was not surprised and immediately casts a strong magic spell on you. Afterwards she disappears.",
    effectNegative: "You lose - 20 health"
  },
  {
    id: 17,
    answers: ["Continue through the dungeon"],
    text: "(Wait, you don’t have any money, that’s why you are here) You tell the lady that you are broke and don’t have anything. She looks disappointed and goes away."
  },
  {
    id: 18,
    answers: ["Attack the orc"],
    text: "You pulled out a sword at the right moment. You were ambushed by an orc.",
    img: orcEnemy,
    css: "img-character"
  },
  {
    id: 19,
    answers: ["Next move"],
    text: "You attack the enemy with all of your strength and skill."
  },
  {
    id: 20,
    answers: ["Continue"],
    text: "You strike first and deal some damage. The orc is highly injured but still strikes back.",
    effectNegative: "You lose 15 health"
  },
  {
    id: 21,
    answers: ["Next"],
    text: "Your companion started to cast a spell and you rushed towards the enemy to distract it. After a short sword fight you jumped back and the witch striked the enemy with the fireball.",

  },
  {
    id: 22,
    answers: ["Loot the orc", "Ignore the dead body"],
    text: "The enemy is dead!",
  },
  {
    id: 23,
    answers: ["Continue"],
    text: "Success! You were able to sneak through the ambush. "
  },
  {
    id: 24,
    answers: ["Next"],
    text: "You loot the dead body and find magic ( definitely a shiny one ) amulet.",
    effectPositive: "You got a Shiny Amulet! Attack + 2",
    img: shinyAmulet,
    css: "img-item"

  },
  {
    id: 25,
    answers: ["Next"],
    text: "Soon you notice that the cave is just a small part of ancient ruins. You don't know much about ancient civilizations, but you definitely heard about the traps they build to protect their Ziggurats from looters and bandits. You decided to be more careful and continue with caution."
  },
  {
    id: 26,
    answers: ["Push the right lever", "Push the left lever"],
    text: "You enter a big hall. It seems that it was some sort of spiritual place. You see an altar and near 2 levers. What will you do?"
  },
  {
    id: 27,
    answers: ["Drink from the red pool", "Drink from the green pool", "Investigate the place", "Back"],
    text: "You hear the noise of the opening door. You go in and find 2 pools filled with red and green substances. "
  },
  {
    id: 28,
    answers: ["Drink anyway", "Don't drink and come back to the hall"],
    text: "You feel refreshed and warm. You are thinking to drink more, but something strange happened with the red pool. It became green.",
    effectPositive: "You gain + 20 health"
  },
  {
    id: 29,
    answers: ["Drink again", "Don't drink and return to the hall"],
    text: "You drank from the green pool and it was a bad idea. Your throat is burning and you are feeling awful.",
    effectNegative: "You lose - 30 health"
  },
  {
    id: 30,
    answers: ["Make a decision"],
    text: "After an investigation and experimenting with small rats, you found out that the rat which was thrown into the green pool never came back. But the rat thrown into the red pool was able to swim to another corner of the pool."
  },
  {
    id: 31,
    answers: ["Rush to attack him", "Try to speak with him"],
    text: "You see a figure of a man dressed like a cultist.",
    img: cultistEnemy,
    css: "img-character"

  },
  {
    id: 32,
    answers: ["Back"],
    text: "He didn't listen and used dark magic spell on you",
    effectNegative: "You lose - 15 health",
    img: cultistEnemy,
    css: "img-character"
  },
  {
    id: 33,
    answers: ["Step back and see around"],
    text: "Your companion was able to stun the cultist with a spell and you took advantage of the situation and sliced the enemy"
  },
  {
    id: 34,
    answers: ["Strike again"],
    text: "You attacked with no fear, but your sword didn't do much damage and he used his dagger to counter-attack",
    effectNegative: "You lose - 10 health",
    img: cultistEnemy,
    css: "img-character"
  },
  {
    id: 35,
    answers: ["Continue"],
    text: "Your attacked with fury and killed the enemy. Well, he still managed to damage you with his weapon.",
    effectNegative: "You lose - 10 health"
  },
  {
    id: 36,
    answers: ["Next"],
    text: "Your attacked with fury ( and with Shiny Sword ) and killed the enemy."
  },
  {
    id: 37,
    answers: ["Loot the dead cultist", "Continue"],
    text: "The cultist is dead!"
  },
  {
    id: 38,
    answers: ["Next"],
    text: "You loot the cultist and found a Rare Magic Ring. Suddenly a magic seal attacks you! ( His dead body had a trap )",
    effectNegative: "You lose - 30 health",
    effectPositive: "You gain Rare Shiny Magic Ring. Attack + 3",
    img: shinyRareRing,
    css: "img-rare-item"

  },
  {
    id: 39,
    answers: ["Go to next floor"],
    text: "You look around and notice that the altar is different now. The secret dor was opened."
  },
  {
    id: 40,
    answers: ["Go to credits", "Start again"],
    text: "Congratulations! You finished 1 floor of THE DUNGEON!"
  },
  {
    id: 41,
    answers: ["Turn around"],
    text: "You feel sudden pain from the back! Someone attacked you with a spell!",
    effectNegative: "You lose - 15 health"
  },
  {
    id: 42,
    answers: ["Try again"],
    text: "You are dead! Try different approach."
  },
];

export default GameData;
